import Image from 'next/image';

export const SocialMediaIcons = () => {
  return (
    <div className="flex gap-4 md:gap-3">
      {' '}
      <a
        href="https://www.instagram.com/tripshepherd/"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263648/f6jqxiglvzal2dafglqj.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="https://www.instagram.com/tripshepherd/"
        />
      </a>
      <a
        href="https://www.facebook.com/tripshepherd/"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/qymiqavkrqdhr5yhtkdx.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="https://www.facebook.com/NiagaraFallsTours"
        />
      </a>
      <a
        href="https://www.pinterest.ca/Tripshepherd/"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/wrb0blpujbh0lklymtqc.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="https://www.pinterest.ca/Tripshepherd/"
        />
      </a>
      <a
        href="https://twitter.com/seesighttours?lang=en"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/idhwbmmwevpliasgagg8.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="https://twitter.com/seesighttours?lang=en"
        />
      </a>
      <a
        href="https://www.youtube.com/@tripshepherd"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263646/m3ltnz8zck5oqotitamu.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="https://www.youtube.com/@tripshepherd"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/tripshepherd"
        target="_blank"
        rel="noreferrer noopener"
        aria-label="This is an external link (opens in a new tab)"
      >
        <Image
          src={
            'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/msnxb1efmgranonrfzlw.svg'
          }
          width={38}
          quality={100}
          height={38}
          alt="linkedin profile"
        />
      </a>
    </div>
  );
};
