import { useState } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
const Link = dynamic(() => import('next/link'));
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const CalendarPaxModal = dynamic(() => import('../CalendarAndPax/Modal'));
import {
  optimizeImageUrl,
  optimizeVideoUrl
} from '../../utils/videoLinkOptimize';
import dynamic from 'next/dynamic';
import {
  CarouselItem,
  CarouselVideo,
  TourCardProps,
  TourCardType
} from '../../types/pages/landing-page';
import {
  removeDuplicateCountryName,
  toTitleCase
} from '../../utils/removeDuplicateCountryName';
import React from 'react';
import { useRouter } from 'next/router';
import { LoadingSpinner } from '../../shared-components/LoadingSpinner/LoadingSpinner';
import ShareButton from '../../shared-components/AttractionCard/ShareButton';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const StyledSwiper = styled(Swiper)`
  // Set the desired styles for the Swiper slider here
  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
  .swiper-pagination {
    margin-bottom: 15px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    border-radius: 50%;
    opacity: 0.5;
    z-index: 10;

    &::after {
      color: rgba(170, 170, 170, 1);
      position: absolute;
      width: 7px;
      height: 7px;
      top: 42%;
      left: 55%;
      font-size: 0.75rem;
      transform: translate(-50%, -50%);
      border-style: solid;
      border-color: #fff;
      z-index: 50;
    }
  }

  .swiper-button-next {
    background-color: #fff;
    opacity: 1;
    right: 5px;
  }

  .swiper-button-prev {
    left: 5px;
    background-color: #fff;
    opacity: 1;
    &::after {
      left: 40%;
    }
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    opacity: 1;
  }
  .swiper-button-disabled {
    display: none;
  }
`;

export const SnippetStyle = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const SeparatorLine = styled.div`
  height: 1px;
  background: rgba(19, 19, 19, 0.08);
  border: 0.08px rgba(19, 19, 19, 0.08);
`;

const LandingPageTourCard = React.forwardRef<HTMLDivElement, TourCardProps>(
  ({ tourCard }, ref) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openMenuIndex, setOpenMenuIndex] = useState<string | null>(null);
    const router = useRouter();

    const handleOpenMenu = (index: string) => {
      if (openMenuIndex !== index) {
        setOpenMenuIndex(index);
      }
    };

    const handleCloseMenu = () => {
      setOpenMenuIndex(null);
    };

    const isToursOrCityPage =
      router.pathname === '/tours' || router.pathname === '/[slug]';

    const handleMouseEnter = () => {
      setIsHovering(true);
    };

    const handleMouseLeave = () => {
      setIsHovering(false);
    };

    const getDurationHoursFromMinutes = (duration: number) => {
      const minutesInDay = 1440;
      if (duration >= minutesInDay) {
        const days = duration / minutesInDay;
        return Number.isInteger(days)
          ? `${days} Days`
          : `${days.toFixed(1)} Days`;
      } else {
        const hours = duration / 60;
        return Number.isInteger(hours)
          ? `${hours} Hours`
          : `${hours.toFixed(1)} Hours`;
      }
    };

    const firstItem = tourCard.carousel?.[0];
    const isCarouselVideo = (item: CarouselItem): item is CarouselVideo => {
      return 'video' in item && item.video !== undefined;
    };

    const handleCardClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const target = e.target as HTMLElement;
      if (target.closest('.share-button')) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      setIsLoading(true);
      router.push(`tours/${tourCard?.slug}`);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    return tourCard && tourCard.slug ? (
      <div className="cursor-pointer">
        <div
          id="tour-section"
          className={`relative flex flex-col rounded-2xl hover:shadow-md border ${
            isLoading ? 'opacity-75' : ''
          } border-[#EBEBEF] h-full xxsm:items-center p-2`}
          ref={ref}
          onClick={handleCardClick}
        >
          <div className={`h-[220px] sm:h-[226px] w-full relative`}>
            <div
              className="w-[100%] h-[100%] relative rounded-xl !overflow-hidden"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {tourCard?.highlight &&
                (tourCard.highlight === 'AWARD WINNER!' ? (
                  <div className="absolute z-30 max-w-[300px] lg:max-w-[250px] py-2 px-2">
                    <Image
                      src="/award-winner.png"
                      width={100}
                      height={100}
                      quality={100}
                      alt="award-winner-logo"
                      className="w-[50px] h-[56px] xl:w-[70px] xl:h-[76px] "
                    />
                  </div>
                ) : (
                  <p
                    className={`absolute max-w-[300px] lg:max-w-[250px] py-1 px-2.5 text-white bg-[#f15c5a] z-30 font-medium text-xs sm:text-sm rounded-tl-[12px] rounded-br-[12px]`}
                  >
                    {toTitleCase(tourCard.highlight)}
                  </p>
                ))}

              <div className="absolute top-2 right-2 z-30">
                <ShareButton
                  isOpen={openMenuIndex === tourCard.id}
                  onOpen={() => handleOpenMenu(tourCard.id)}
                  onClose={handleCloseMenu}
                  slug={tourCard.slug}
                />
              </div>
              <div className="w-[100%] h-[100%]">
                {firstItem &&
                  (isCarouselVideo(firstItem) ? (
                    <video
                      src={optimizeVideoUrl(firstItem.video)}
                      muted
                      loop
                      playsInline
                      className="w-[100%] h-[100%] object-cover"
                      autoPlay
                    ></video>
                  ) : (
                    <Image
                      src={optimizeImageUrl(firstItem.image)}
                      width={350}
                      height={350}
                      alt={firstItem.alt}
                      className="w-[100%] h-[100%] rounded-xl hover:scale-105 ease-in-out duration-200"
                      placeholder="blur"
                      blurDataURL="/placeholder.webp"
                      priority
                    />
                  ))}
              </div>
            </div>
          </div>

          <Link href={`tours/${tourCard?.slug}`} className="w-full">
            <div className="px-1">
              <div id="description" className="flex flex-col gap-2">
                <div className="flex mt-2.5 justify-between items-center">
                  {(tourCard.city || tourCard.country) && (
                    <div className="flex gap-1.5 items-center">
                      <Image
                        src="gray-location-icon.svg"
                        width={14}
                        height={14}
                        alt="location-icon"
                      />
                      <p className="text-black13 opacity-60 text-xs sm:text-sm">
                        {tourCard.city === 'Prince Edward Island'
                          ? 'PEI, Canada'
                          : removeDuplicateCountryName(
                              tourCard.city,
                              tourCard.country
                            )}
                      </p>
                    </div>
                  )}

                  {!isToursOrCityPage &&
                    tourCard &&
                    tourCard.reviews_count > 0 &&
                    tourCard.avg_rating > 0 && (
                      <Link href={`/tours/${tourCard.slug}#reviews`}>
                        <span className="flex items-center gap-1 hover:text-darkBlue">
                          <Image
                            src={'/homestar.svg'}
                            width={14}
                            height={14}
                            alt="rating-star"
                            className="mb-[2px]"
                          />
                          <span className="text-[14px]">
                            {tourCard.avg_rating}
                          </span>
                          <span className="text-[12px] text-[#777777]">
                            ({tourCard.reviews_count})
                          </span>
                        </span>
                      </Link>
                    )}
                </div>
                <div
                  id="heading"
                  className="h-[48px] overflow-hidden"
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  <h2
                    className={`text-[15px] sm:text-base leading-[22px] sm:leading-[24px] font-semibold line-clamp-2 text-ellipsis ${
                      isToursOrCityPage ? 'inline' : ''
                    }`}
                  >
                    {tourCard?.name}
                  </h2>
                </div>

                <div id="snippet">
                  <SnippetStyle
                    className={`text-sm leading-[22px] sm:text-base sm:leading-[24px] text-black13 opacity-60 font-normal h-[48px] overflow-hidden ${
                      !isToursOrCityPage ? 'mb-4' : ''
                    }`}
                  >
                    {isLoading ? (
                      <div className="flex justify-center items-center h-full">
                        <LoadingSpinner className="w-5 h-5" />
                      </div>
                    ) : (
                      tourCard?.snippet
                    )}
                  </SnippetStyle>
                </div>
                {isToursOrCityPage && (
                  <div className="mb-2 h-[24px]">
                    {tourCard &&
                      tourCard.reviews_count > 0 &&
                      tourCard.avg_rating > 0 && (
                        <Link href={`/tours/${tourCard.slug}#reviews`}>
                          <span
                            className={`items-center gap-1 hover:text-darkBlue ${
                              isToursOrCityPage ? 'inline-flex' : 'flex'
                            } `}
                          >
                            <Image
                              src={'/homestar.svg'}
                              width={14}
                              height={14}
                              alt="rating-star"
                              className="mb-[2px]"
                            />
                            <span className="text-[14px]">
                              {tourCard.avg_rating}
                            </span>
                            <span className="text-[12px] text-[#777777]">
                              ({tourCard.reviews_count})
                            </span>
                          </span>
                        </Link>
                      )}
                  </div>
                )}
              </div>
              <SeparatorLine />
              <div
                id="checkout_buttons"
                className="flex justify-between items-center pt-2 pb-1"
              >
                <div id="tour-time">
                  <p className="text-black13 opacity-70 text-[13px] sm:text-sm font-normal">
                    {getDurationHoursFromMinutes(tourCard?.duration)}
                  </p>
                </div>
                <p className="">
                  <span className="text-[13px] sm:text-sm font-normal text-black13 opacity-70">
                    From
                  </span>
                  &nbsp;
                  <span
                    className={`text-base sm:text-lg font-semibold text-black13`}
                  >{`$${tourCard.price}`}</span>
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ) : null;
  }
);

LandingPageTourCard.displayName = 'LandingPageTourCard';
export default LandingPageTourCard;
