const organizationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'tripshepherd',
  email: 'info@seesight-tours.com',
  telePhone: '+1-289-271-9767',
  url: 'https://www.tripshepherd.com/',
  Image:
    'https://res.cloudinary.com/see-sight-tours/image/upload/v1704291035/Trip_sheperd_2_mfyxdg.webp',
  Logo: 'https://res.cloudinary.com/see-sight-tours/image/upload/v1704291035/Trip_sheperd_2_mfyxdg.webp',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '5779 Desson Avenue Niagara Falls',
    addressLocality: 'Ontario',
    addressRegion: 'Canada',
    postalCode: 'L2G 3T5'
  },
  sameAs: [
    'https://www.instagram.com/seesighttours/',
    'https://www.facebook.com/NiagaraFallsTours',
    'https://twitter.com/seesighttours?lang=en',
    'https://www.youtube.com/user/SeeSightTours'
  ],
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4.9',
    ratingCount: '5300',
    bestRating: '5',
    worstRating: '1'
  }
};
export const WebsiteSchema = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Tripshepherd',
  alternateName: 'Tripshepherd',
  url: 'https://www.tripshepherd.com/'
};
export default organizationSchema;
