const getCurrentDayIndex = () => {
  const currentDate = new Date();
  let currentDayIndex = currentDate.getDay();
  currentDayIndex = (currentDayIndex + 6) % 7;

  return currentDayIndex;
};
export const getOpeningHoursForCurrentDay = (
  openingHours: string[] | undefined
): string => {
  if (!openingHours || openingHours.length === 0) {
    return '';
  }

  const currentDayIndex = getCurrentDayIndex();

  if (currentDayIndex < 0 || currentDayIndex >= openingHours.length) {
    return '';
  }

  const openingHoursForCurrentDay = openingHours[currentDayIndex];
  const extractedHours = openingHoursForCurrentDay.replace(/^[^:]+:\s*/, '');
  return extractedHours;
};
