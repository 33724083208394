import React, { Dispatch, SetStateAction } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {
  SearchDropdownAttraction,
  SearchDropdownThingsTodo,
  TourCardType,
  TourCity
} from '../../types/pages/landing-page';
import { AttractionCardType } from '../../types/components/attraction-card';
import { useRouter } from 'next/router';
import { useUpdateQueryParams } from '../../utils/useUpdateQueryParams';
import { useSearchContext } from '../../utils/context/searchContext';

interface DropdownContentProps {
  isLandingToursPage: boolean;
  isDropdownVisible: boolean;
  setDropdownVisible: Dispatch<SetStateAction<boolean>>;
  dropdownAttractions: AttractionCardType[];
  defaultAttractions?: AttractionCardType[] | SearchDropdownAttraction[];
  defaultCities?: TourCity[];
  defaultTours?: TourCardType[];
  defaultThingsTodo?: SearchDropdownThingsTodo[];
  handleItemClick: (attraction: AttractionCardType) => void;
  handleSelectCity: (cityName: string) => void;
  removeDuplicateCountryName: (cityName: string, countryName: string) => string;
  width: string;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

const ItemList: React.FC<{
  items?: any[];
  handleItemClick: (item: any) => void;
  renderItem: (item: any) => React.ReactNode;
}> = ({ items, handleItemClick, renderItem }) => (
  <>
    {items?.map(item => (
      <div
        key={item.id || item.objectID}
        className={`hover:bg-red-100 hover:bg-opacity-60 rounded-full cursor-pointer flex gap-2.5 pt-1.5 pb-[0.35rem] px-5`}
        onClick={() => handleItemClick(item)}
      >
        {renderItem(item)}
      </div>
    ))}
  </>
);

const DropdownContent: React.FC<DropdownContentProps> = ({
  isLandingToursPage,
  isDropdownVisible,
  setDropdownVisible,
  dropdownAttractions,
  defaultAttractions,
  defaultCities,
  defaultTours,
  defaultThingsTodo,
  handleItemClick,
  handleSelectCity,
  removeDuplicateCountryName,
  width,
  dropdownRef
}) => {
  const router = useRouter();
  const updateQueryParams = useUpdateQueryParams();
  const { selectedCategory, searchQuery }: any = useSearchContext();

  const filteredThingsTodo = defaultThingsTodo?.filter(
    item => item.thingsToDo !== null && item.thingsToDo !== undefined
  );

  const showDropdown = () => {
    if (
      (!isLandingToursPage &&
        isDropdownVisible &&
        dropdownAttractions.length > 0) ||
      (isLandingToursPage &&
        isDropdownVisible &&
        ((defaultCities && defaultCities?.length > 0) ||
          (defaultTours && defaultTours?.length > 0)))
    ) {
      return true;
    } else {
      return false;
    }
  };
  //Dropdown Content for Things todo Landing page
  const renderAttractionItem = (attraction: AttractionCardType) => (
    <>
      <Image
        src={'/location-circled-icon.svg'}
        alt="location-icon"
        width={30}
        height={30}
        className="w-8 h-8 md:h-9 md:w-9"
      />
      <div className="flex flex-col gap-1 justify-center">
        <h2 className="text-black13 text-xs sm:text-sm md:text-base font-medium leading-tight">
          {attraction.name}
          {attraction.city && `, ${attraction.city}`}
        </h2>
        {attraction.country && (
          <p className="text-black13 opacity-80 text-xs sm:text-sm md:text-base font-medium leading-tight">
            {attraction.country}
          </p>
        )}
      </div>
    </>
  );

  const renderThingsToDoItem = (thingstodo: SearchDropdownThingsTodo) =>
    thingstodo.thingsToDo !== null &&
    thingstodo.thingsToDo !== undefined && (
      <Link
        key={thingstodo.id}
        className="cursor-pointer flex gap-2.5"
        href={thingstodo.thingsToDo}
      >
        <Image
          src={'/location-circled-icon.svg'}
          alt="location-icon"
          width={30}
          height={30}
          className="w-8 h-8 md:h-9 md:w-9"
        />
        <div className="flex flex-col gap-1 justify-center">
          <p className="text-black13 text-xs sm:text-sm md:text-base font-medium leading-tight">
            {thingstodo.city}
            {thingstodo.country && `, ${thingstodo.country}`}
          </p>
        </div>
      </Link>
    );

  //Dropdown Content for Tours Page
  const renderCityItem = (city: TourCity) => (
    <>
      <Image
        src={'/location-circled-icon.svg'}
        alt="location-icon"
        width={30}
        height={30}
        className="w-7 h-7 md:h-9 md:w-9"
      />
      <div className="flex flex-col justify-center">
        <h2 className="text-black13 text-xs sm:text-sm md:text-base font-medium leading-tight">
          {removeDuplicateCountryName(city.name, city.country)}
        </h2>
      </div>
    </>
  );

  const renderTourItem = (tour: TourCardType) => (
    <Link
      key={tour.objectID}
      href={`/tours/${tour.slug}`}
      className="cursor-pointer flex gap-2.5"
    >
      <Image
        src={'/location-circled-icon.svg'}
        alt="location-icon"
        width={30}
        height={30}
        className="w-7 h-7 md:h-9 md:w-9"
      />
      <div className="flex flex-col gap-1 justify-center">
        <h2 className="text-black13 text-xs sm:text-sm md:text-base font-medium leading-tight">
          {tour.name}
        </h2>
        <p className="text-black13 opacity-80 text-xs sm:text-sm md:text-base font-medium leading-tight">
          {removeDuplicateCountryName(tour.city, tour.country)}
        </p>
      </div>
    </Link>
  );

  return showDropdown() ? (
    <div
      className={`absolute top-[80%] flex flex-col gap-4 md:gap-6 z-[1000] mt-2 border border-[#EEE] rounded-3xl bg-white py-[22px] shadow-md transition-opacity ${width}`}
      ref={dropdownRef}
    >
      {((defaultAttractions && defaultAttractions.length > 0) ||
        (defaultCities && defaultCities.length > 0)) && (
        <div className="flex flex-col gap-0 md:gap-2">
          <h2 className="text-black13 opacity-70 text-xs md:text-sm font-medium leading-tight pl-5 mb-1.5 md:mb-0">
            {isLandingToursPage ? 'Destinations' : 'Top Attractions'}
          </h2>
          {!isLandingToursPage ? (
            <ItemList
              items={defaultAttractions}
              handleItemClick={handleItemClick}
              renderItem={renderAttractionItem}
            />
          ) : (
            <ItemList
              items={defaultCities}
              handleItemClick={city => handleSelectCity(city.name)}
              renderItem={renderCityItem}
            />
          )}
        </div>
      )}

      {((defaultTours && defaultTours.length > 0) ||
        (filteredThingsTodo && filteredThingsTodo.length > 0)) && (
        <div className="flex flex-col gap-2">
          <h2 className="text-black13 opacity-70 text-xs md:text-sm font-medium leading-tight pl-5">
            {isLandingToursPage && 'Top Tours'}
            {filteredThingsTodo &&
              filteredThingsTodo?.length > 0 &&
              'Things To Do'}
          </h2>
          {isLandingToursPage ? (
            <ItemList
              items={defaultTours}
              handleItemClick={() => {
                setDropdownVisible(false);
                updateQueryParams(
                  {
                    ...router.query,
                    q: searchQuery,
                    category: selectedCategory
                  },
                  '/'
                );
              }}
              renderItem={renderTourItem}
            />
          ) : (
            <ItemList
              items={filteredThingsTodo}
              handleItemClick={() => {}}
              renderItem={renderThingsToDoItem}
            />
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default DropdownContent;
