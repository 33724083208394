import Image from 'next/image';
import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch';
import {
  ELandingPage,
  SearchDropdownAttraction,
  SearchDropdownThingsTodo,
  TourCardType,
  TourCity
} from '../../types/pages/landing-page';
import { useRouter } from 'next/router';
import { AttractionCardType } from '../../types/components/attraction-card';
import { useSearchContext } from '../../utils/context/searchContext';
import { DEFAULT_DROPDOWN_ATTRACTIONS } from '../../pages/things-to-do';
import { removeDuplicateCountryName } from '../../utils/removeDuplicateCountryName';
import DropdownContent from './DropdownContent';
import { DEFAULT_DROPDOWN_CITIES } from '../../pages';
import { useUpdateQueryParams } from '../../utils/useUpdateQueryParams';
import { styled } from 'styled-components';

interface IProps extends UseSearchBoxProps {
  width: string;
  height: string;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  iconStyles: IconStyles;
  defaultAttractions?: AttractionCardType[] | SearchDropdownAttraction[];
  defaultThingsTodo?: SearchDropdownThingsTodo[];
  setSelectedAttraction?: Dispatch<
    SetStateAction<AttractionCardType | SearchDropdownAttraction | undefined>
  >;
  defaultCities?: TourCity[];
  defaultTours?: TourCardType[];
  pagetype: ELandingPage;
  setSelectedCity?: Dispatch<SetStateAction<string>>;
}
interface IconStyles {
  search: string;
  clear: string;
}

// Styling to prevent automatic zoom in on ios search input
const Input = styled.input`
  padding: 14px;
  padding-left: 8%;
  font-size: 16px;
  width: 133.333333333%;
  transform: scale(0.75);
  transform-origin: left top;
  margin-bottom: -10px;
  margin-right: -33.333333333%;

  @media (min-width: 768px) {
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 0;
    transform: scale(1);
    padding-left: 5%;
  }

  @media (min-width: 1920px) {
    font-size: 16px;
  }
`;

export function CustomSearchBox(props: IProps) {
  const { refine } = useSearchBox(props);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const updateQueryParams = useUpdateQueryParams();

  //fetching states from search context for handling search bars on both navbar and category section
  const {
    dropdownAttractions,
    setLandingPageCenterPoint,
    setSelectedAttraction,
    showFullMap,
    setDropdownAttractions,
    selectedCategory,
    setSelectedCategory,
    searchQuery,
    dropdownCities,
    dropdownTours,
    setDropdownCities
  }: any = useSearchContext();

  const isLandingToursPage = props.pagetype == ELandingPage.TOURS_PAGE;

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    props.setSearchTerm((router.query.q as string) || '');
    setSelectedCategory(
      (router.query.category as string) ||
        (router.pathname === '/things-to-do' ? 'All Attractions' : 'All Tours')
    );
    refine(router.query.q as string);
  }, [router.query.q, router.query.category]);

  //For handling Dropdown Open/Close scenarios
  useEffect(() => {
    if (searchQuery) {
      props.setSearchTerm(searchQuery);
      if (
        router.pathname === '/things-to-do' &&
        dropdownAttractions.length === 0
      ) {
        setDropdownVisible(false);
      } else if (
        router.pathname === '/' &&
        dropdownCities.length === 0 &&
        dropdownTours.length === 0
      ) {
        setDropdownVisible(false);
      }
    } else {
      setDropdownVisible(false);
    }
  }, [dropdownAttractions, selectedCategory, dropdownCities, dropdownTours]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
        // updateQueryParams({
        //   ...router.query,
        //   q: props.searchTerm,
        //   category: selectedCategory
        // });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.searchTerm, selectedCategory]);

  const resetSearch = () => {
    props.setSearchTerm('');
    refine('');
    // Update the URL without the 'q' parameter and add the 'category' parameter
    updateQueryParams(
      { q: '', category: selectedCategory },
      `${
        props.pagetype === ELandingPage.THINGS_TO_DO_PAGE
          ? `/things-to-do`
          : `/`
      }`
    );

    if (showFullMap) {
      setLandingPageCenterPoint({
        lat: null,
        lng: null
      });
      selectedCategory == 'All Attractions'
        ? setSelectedAttraction(DEFAULT_DROPDOWN_ATTRACTIONS[0])
        : setSelectedAttraction(undefined);
    } else {
      props.setSelectedAttraction && props.setSelectedAttraction(undefined);
      props.setSelectedCity && props.setSelectedCity('');
    }
    setDropdownAttractions(DEFAULT_DROPDOWN_ATTRACTIONS);
    setDropdownCities(DEFAULT_DROPDOWN_CITIES);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value.trim();
    const searchTerm = trimmedValue === '' ? '' : e.target.value;
    props.setSelectedAttraction && props.setSelectedAttraction(undefined);
    props.setSelectedCity && props.setSelectedCity('');
    props.setSearchTerm(searchTerm);
    refine(searchTerm);
    updateQueryParams(
      {
        ...router.query,
        q: searchTerm,
        category: selectedCategory
      },
      `${
        props.pagetype === ELandingPage.THINGS_TO_DO_PAGE
          ? `/things-to-do`
          : `/`
      }`
    );
    setLandingPageCenterPoint({ lat: null, lng: null });
  };

  const handleFocus = () => {
    setDropdownVisible(true);
  };

  //For Selecting Attraction on Things todo page
  const handleSelectAttractionClick = (item: any) => {
    props.setSearchTerm(item.name);
    props.setSelectedAttraction && props.setSelectedAttraction(item);
    setLandingPageCenterPoint({ lat: null, lng: null });
    setDropdownVisible(false);
    // retaining search keyword whenever we change route and keep scrollY top to 20 avoid blinking repeatedly
    updateQueryParams(
      {
        ...router.query,
        q: item.name,
        category: selectedCategory
      },
      '/things-to-do'
    );
  };

  // For Selecting City on Tours Page
  const handleSelectCity = (city: string) => {
    props.setSelectedCity && props.setSelectedCity(city);
    props.setSearchTerm(city);
    setDropdownVisible(false);
    // retaining search keyword whenever we change route and keep scrollY top to 20 avoid blinking repeatedly
    updateQueryParams(
      {
        ...router.query,
        q: city,
        category: selectedCategory
      },
      '/'
    );
  };

  // Handle Key Down to close dropdown and hide keyboard(on mobile) when Enter button is pressed
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        // retaining search keyword whenever we change route and keep scrollY top to 20 avoid blinking repeatedly
        // updateQueryParams({
        //   ...router.query,
        //   q: props.searchTerm,
        //   category: selectedCategory
        // });

        setDropdownVisible(false);
        inputRef.current && inputRef.current.blur();
      } else {
        setDropdownVisible(true);
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [searchQuery]);

  //When we remove search by using backspace
  useEffect(() => {
    if (!props.searchTerm || props.searchTerm == '') {
      props.setSearchTerm('');
      refine('');
      if (
        selectedCategory !== 'All Tours' &&
        selectedCategory !== 'All Attractions'
      ) {
        // Update the URL without the 'q' parameter and add the 'category' parameter
        updateQueryParams(
          { q: '', category: selectedCategory },
          `${
            props.pagetype === ELandingPage.THINGS_TO_DO_PAGE
              ? `/things-to-do`
              : `/`
          }`
        );
      }

      if (showFullMap) {
        setLandingPageCenterPoint({
          lat: null,
          lng: null
        });

        selectedCategory == 'All Attractions'
          ? setSelectedAttraction(DEFAULT_DROPDOWN_ATTRACTIONS[0])
          : setSelectedAttraction(undefined);
      } else {
        props.setSelectedAttraction && props.setSelectedAttraction(undefined);
        props.setSelectedCity && props.setSelectedCity('');
      }
      setDropdownAttractions(DEFAULT_DROPDOWN_ATTRACTIONS);
      setDropdownCities(DEFAULT_DROPDOWN_CITIES);
    }
  }, [props.searchTerm]);

  return (
    <div className="flex justify-center w-full">
      <div
        className={`flex justify-center relative ${props.width}`}
        id="searchbar"
      >
        <Input
          ref={inputRef}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          autoFocus={false}
          placeholder="Where are you going?"
          spellCheck={false}
          maxLength={50}
          minLength={2}
          type="search"
          value={props.searchTerm}
          onChange={handleChange}
          onFocus={handleFocus}
          onClick={handleFocus}
          className={`rounded-full h-[60px] text-start shadow-md text-black13 font-medium bg-white w-[100%] border-[#EEE] border-[1px] focus:outline-none ${props.height}`}
        />
        <button
          className={`w-fit leading-[0.5rem] cursor-pointer absolute ${
            props.searchTerm.length > 0
              ? `bg-lightGray border-lightGray active:bg-grayEF hover:bg-grayEF hover:border-grayEF hover:shadow-lg focus:shadow-lg font-medium border-2 sm:leading-none focus:outline-none focus:ring-0 active:shadow-lg right-2 ${props.iconStyles.clear}`
              : `bg-primary border-primary active:bg-red-500 text-white hover:bg-red-400 hover:shadow-lg focus:shadow-lg font-medium border-2 sm:leading-none focus:outline-none focus:ring-0 active:shadow-lg right-2 ${props.iconStyles.search}`
          } rounded-full transition duration-150 ease-in-out p-2`}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          onClick={() =>
            props.searchTerm.length > 0
              ? resetSearch()
              : refine(props.searchTerm)
          }
        >
          <Image
            priority={true}
            src={
              props.searchTerm.length > 0
                ? '/close-icon.svg'
                : '/search-icon.svg'
            }
            width={44}
            height={44}
            alt={props.searchTerm.length > 0 ? 'close icon' : 'search icon'}
            className={
              props.searchTerm.length > 0 ? 'w-3 md:w-3.5' : 'w-4 md:w-5.5'
            }
          />
        </button>
      </div>
      <DropdownContent
        isLandingToursPage={isLandingToursPage}
        isDropdownVisible={isDropdownVisible}
        setDropdownVisible={setDropdownVisible}
        dropdownAttractions={dropdownAttractions}
        defaultAttractions={props.defaultAttractions}
        defaultCities={props.defaultCities}
        defaultTours={props.defaultTours}
        defaultThingsTodo={props.defaultThingsTodo}
        handleItemClick={handleSelectAttractionClick}
        handleSelectCity={handleSelectCity}
        removeDuplicateCountryName={removeDuplicateCountryName}
        width={props.width}
        dropdownRef={dropdownRef}
      />
      {/* {((!isLandingToursPage &&
        isDropdownVisible &&
        dropdownAttractions.length > 0) ||
        (isLandingToursPage && isDropdownVisible)) && (
        <div
          className={`absolute top-[80%] flex flex-col gap-6 z-[1000] mt-2 border border-[#EEE] rounded-3xl bg-white py-[22px] shadow-md transition-opacity ${props.width}`}
          ref={dropdownRef}
        >
          <div className="flex flex-col gap-2">
            <h2 className="text-black13 opacity-70 text-sm font-medium leading-tight pl-5">
              {isLandingToursPage ? 'Destinations' : 'Top Attractions'}
            </h2>
            {!isLandingToursPage ? (
              <>
                {props.defaultAttractions?.map(attraction => (
                  <div
                    key={(attraction as AttractionCardType).id}
                    className="hover:bg-red-100 hover:bg-opacity-60 rounded-full cursor-pointer flex gap-2.5 py-1.5 px-5"
                    onClick={() => handleItemClick(attraction)}
                  >
                    <Image
                      src={'/location-circled-icon.svg'}
                      alt="location-icon"
                      width={38}
                      height={38}
                    />
                    <div className="flex flex-col gap-1 justify-center">
                      <h2 className="text-black13 text-base font-medium leading-tight">
                        {attraction.name}
                        {attraction.city && `, ${attraction.city}`}
                      </h2>
                      {attraction.country && (
                        <p className="text-black13 opacity-80 text-base font-medium leading-tight">
                          {attraction.country}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {props.defaultCities?.map(city => (
                  <div
                    key={city.objectID}
                    className="hover:bg-red-100 hover:bg-opacity-60 rounded-full cursor-pointer flex gap-2.5 py-1.5 px-5"
                    onClick={() => handleSelectCity(city.name)}
                  >
                    <Image
                      src={'/location-circled-icon.svg'}
                      alt="location-icon"
                      width={38}
                      height={38}
                    />
                    <div className="flex flex-col gap-1 justify-center">
                      <h2 className="text-black13 text-base font-medium leading-tight">
                        {removeDuplicateCountryName(city.name, city.country)}
                      </h2>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          {((props.defaultTours && props.defaultTours.length > 0) ||
            (props.defaultThingsTodo &&
              props.defaultThingsTodo.length > 0)) && (
            <div className="flex flex-col gap-2">
              <h2 className="text-black13 opacity-70 text-sm font-medium leading-tight pl-5">
                {isLandingToursPage ? 'Top Tours' : 'Things To Do'}
              </h2>
              {isLandingToursPage ? (
                <>
                  {props.defaultTours?.map(tour => (
                    <div
                      key={tour.objectID}
                      className="hover:bg-red-100 hover:bg-opacity-60 rounded-full cursor-pointer flex gap-2.5 py-1.5 px-5"
                      onClick={() => setDropdownVisible(false)}
                    >
                      <Image
                        src={'/location-circled-icon.svg'}
                        alt="location-icon"
                        width={38}
                        height={38}
                      />
                      <div className="flex flex-col gap-1 justify-center">
                        <h2 className="text-black13 text-base font-medium leading-tight">
                          {`${tour.name}`}
                        </h2>
                        <p className="text-black13 opacity-80 text-base font-medium leading-tight">
                          {removeDuplicateCountryName(tour.city, tour.country)}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {props.defaultThingsTodo?.map(
                    thingstodo =>
                      thingstodo &&
                      thingstodo.thingsToDo !== null &&
                      thingstodo.thingsToDo !== undefined && (
                        <Link
                          key={thingstodo.id}
                          className="hover:bg-red-100 hover:bg-opacity-60  rounded-full cursor-pointer flex gap-2.5 py-1.5 px-5"
                          href={thingstodo.thingsToDo}
                        >
                          <Image
                            src={'/location-circled-icon.svg'}
                            alt="location-icon"
                            width={38}
                            height={38}
                          />

                          <div className="flex flex-col gap-1 justify-center">
                            <p className="text-black13 text-base font-medium leading-tight">
                              {thingstodo.city}
                              {thingstodo.country && `, ${thingstodo.country}`}
                            </p>
                          </div>
                        </Link>
                      )
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )} */}
    </div>
  );
}
