import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import SwiperCore, { Autoplay, Navigation, EffectFade } from 'swiper';
SwiperCore.use([Autoplay, Navigation, EffectFade]);

import Head from 'next/head';
import { Configure, InstantSearch } from 'react-instantsearch';

import algoliasearch from 'algoliasearch/lite';

import { ELandingPage, TourCardType } from '../types/pages/landing-page';
import { useSearchContext } from '../utils/context/searchContext';
import { useDebounce } from '../utils/debounce';
import { CustomSearchBox } from '../shared-components/SearchBar/SearchBar';
import AttractionCategories from '../components/Landingpage/components/attractionCategories';
import LandingPageTours from '../components/Tours/LandingPageTours';
import Newsletter from '../layouts/Newsletter/Newsletter';
import client from '../apollo-client';
import {
  FETCH_ALL_CITIES,
  FETCH_ALL_CITIES_INTERFACE
} from '../api/citiesPage';
import replaceText from '../utils/trip-sheppered-text';
import { useUpdateQueryParams } from '../utils/useUpdateQueryParams';
import { GetServerSideProps, GetStaticProps } from 'next';
import { RelatedUrl } from '../types/pages/things-to-do';
import { ATTRACTIONS_BY_POPULAR_CITIES } from '../api/attraction';
import { RelatedBreadcrumbLinks } from '../components/ThingsToDo/RelatedBreadcrumbLinks/RelatedBreadcrumbLinks';

interface BreadcrumbLink {
  id: string;
  name: string;
  slug: string;
}

interface ToursProps {
  popular_cities_breadcrumbs: RelatedUrl[];
}

export const DEFAULT_DROPDOWN_CITIES = [
  {
    name: 'Niagara Falls, Canada',
    country: 'Canada',
    objectID: '19a8fbd2a47635_dashboard_generated_id'
  },
  {
    name: 'Halifax',
    country: 'Canada',
    objectID: '1c669b8bfee4f9_dashboard_generated_id'
  },
  {
    name: 'Toronto',
    country: 'Canada',
    objectID: 'c1460d6155952_dashboard_generated_id'
  }
];

const Tours = ({ popular_cities_breadcrumbs }: ToursProps) => {
  const {
    searchQuery,
    setSearchQuery,
    dropdownTours,
    setDropdownTours,
    dropdownCities,
    setDropdownCities,
    selectedTourCity,
    setSelectedTourCity,
    selectedCategory,
    setSelectedCategory
  }: any = useSearchContext();
  const updateQueryParams = useUpdateQueryParams();
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollOccurred, setScrollOccurred] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tours, setTours] = useState<TourCardType[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [appendMode, setAppendMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showNewsletter, setShowNewsletter] = useState(false);
  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
  );
  const [isMobileScreen, setIsMobileScreen] = useState(
    typeof window !== 'undefined' && window.innerWidth <= 640
  );

  // Determine hits per page based on screen size
  const HITS_PER_PAGE = isMobileScreen ? 10 : 20;

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobileScreen(window.innerWidth <= 640);
    };

    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const mapRef = useRef<L.Map>(null);

  const getClassName = () => {
    if (!isScrolled && scrollOccurred) {
      return 'transition-all duration-300 animate-fade-down';
    } else if (isScrolled) {
      return 'transition-all duration-300 animate-fade-up hidden';
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        currentPage === totalPages && setShowNewsletter(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  useEffect(() => {
    setCurrentPage(1);
    setTimeout(() => {
      if (window.scrollY > 20) {
        window.scrollTo({ top: 20, behavior: 'smooth' });
      }
    }, 1000);
  }, [searchQuery, selectedCategory]);

  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
      if (!scrollOccurred && scrollPosition > 0) {
        setScrollOccurred(true);
      }
    };
  }

  useEffect(() => {
    setSelectedCategory('All Tours');
  }, []);

  const tourCategories = [
    {
      id: '0',
      label: 'All Tours',
      value: 'All Tours',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248139/icons-website/h8fhrijhtbxorqla8urd.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248152/icons-website/xkvqwyylizzcnbiz82oz.svg'
    },
    {
      id: '1',
      label: 'Day Tours',
      value: 'Day Tour',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/gray-day-tour_yycy5r.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/red-day-tour_yewcju.svg'
    },
    {
      id: '2',
      label: 'Walking Tours',
      value: 'Walking Tour',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373531/icons-website/gray-walking-tour_lk1ree.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/red-walking-tour_w8gv5d.svg'
    },
    {
      id: '3',
      label: 'Food Walking Tours',
      value: 'Food Walking Tour',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373531/icons-website/gray-food-walking-tour_u8x8p5.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/red-food-walking-tour_gwdmup.svg'
    },
    {
      id: '4',
      label: 'Night Tours',
      value: 'Night Tour',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/gray-night-tour_dxqlma.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/red-night-tour_git1d4.svg'
    },
    {
      id: '5',
      label: 'Cruises',
      value: 'Cruises',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248189/icons-website/rqyxrqouu55yjhkwjy0m.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248202/icons-website/zmrsxuxktbmbavdshv6j.svg'
    }
  ];

  const toursIndex = searchClient.initIndex('tours');
  const cityIndex = searchClient.initIndex('cities');

  const fetchCities = (query: string, tours: TourCardType[]) => {
    setIsLoading(true);

    cityIndex
      .search(query, {
        hitsPerPage: 50
      })
      .then((response: any) => {
        const { hits } = response;
        const modifiedCities = hits.map((tour: any) => ({
          ...tour,
          id: tour.objectID
        }));

        // Filter cities from search results whose tours match the selected category
        const filteredCities = modifiedCities.filter((searchCity: any) => {
          // Check if any tour in the tours list matches this city and has the selected category
          return tours.some((tour: any) => {
            return (
              tour.city === searchCity.name &&
              tour.category.some(
                (category: string) => category === selectedCategory
              )
            );
          });
        });

        // Set dropdownCities with the filtered cities (up to 3)
        setDropdownCities(
          selectedCategory !== 'All Tours'
            ? filteredCities.slice(0, 3)
            : modifiedCities.slice(0, 3)
        );
      })
      .catch((error: any) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Fetch Tours from Algolia

  const fetchTours = (
    query: string,
    city: string,
    category: string,
    page: number,
    append: boolean
  ) => {
    setIsLoading(true);

    //For category and city filter
    const facetFilters: any = [];
    if (
      (category && category == 'All Tours') ||
      category == 'All Attractions'
    ) {
    } else {
      facetFilters.push(`category:${category}`);
    }
    if (city) {
      facetFilters.push(`city:${city}`);
    }

    toursIndex
      .search(query, {
        page: page - 1,
        hitsPerPage: HITS_PER_PAGE,
        facetFilters: facetFilters
      })
      .then((response: any) => {
        const { hits, nbPages } = response;
        const modifiedTours = hits.map((tour: any) => ({
          ...tour,
          id: tour.objectID
        }));

        if (append) {
          setTours(prevTours => [...prevTours, ...modifiedTours]);
        } else {
          setTours(modifiedTours);
        }

        if (page === 1) setDropdownTours(modifiedTours.slice(0, 2));
        setTotalPages(nbPages);
        fetchCities(query, modifiedTours);
      })
      .catch((error: any) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const debouncedSearchQuery = useDebounce((query: string) => {
    fetchTours(query, selectedTourCity, selectedCategory, 1, false);
  }, 30);

  useEffect(() => {
    setAppendMode(false);
    debouncedSearchQuery(searchQuery);
  }, [searchQuery, selectedCategory, selectedTourCity]);

  useEffect(() => {
    if (appendMode) {
      //When we click on Load More button
      fetchTours(
        searchQuery,
        selectedTourCity,
        selectedCategory,
        currentPage,
        true
      );
    } else {
      fetchTours(
        searchQuery,
        selectedTourCity,
        selectedCategory,
        currentPage,
        false
      );
    }
  }, [currentPage, appendMode]);

  const handleLoadMore = () => {
    setAppendMode(true);
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handleCategoryClick = (category: string) => {
    // Update the selected category and other state variables
    setSelectedCategory(category);
    setCurrentPage(1);
    setAppendMode(false);
    setSearchQuery('');
    setSelectedTourCity('');

    // Update the URL without the 'q' parameter and add the 'category' parameter
    updateQueryParams({ q: '', category: category }, '/');
  };

  return (
    <>
      <Head>
        <meta
          name="og:title"
          content={
            'Tripshepherd (Formerly See Sight Tours) - Best Small Group Tours'
          }
        ></meta>
        <meta
          name="og:description"
          content={
            'Explore Canada and the USA with Tripshepherd (formerly See Sight Tours) offering the best small-group tours. Discover the best destinations, attractions, and top things to do with local tour guides.'
          }
        />
        <title>
          Tripshepherd (Formerly See Sight Tours) - Best Small Group Tours
        </title>
        <meta
          name="description"
          content={
            'Explore Canada and the USA with Tripshepherd (formerly See Sight Tours) offering the best small-group tours. Discover the best destinations, attractions, and top things to do with local tour guides.'
          }
        />
        <link href={'https://www.tripshepherd.com/'} rel="canonical" />
        <meta
          name="og:image"
          content="https://res.cloudinary.com/see-sight-tours/image/upload/v1704291035/Trip_sheperd_2_mfyxdg.webp"
        />
      </Head>

      <main>
        <InstantSearch
          searchClient={searchClient}
          indexName="tours"
          future={{ preserveSharedStateOnUnmount: true }}
        >
          <div
            className={`sticky top-[68px] lg:top-[80px] 3xl:top-[80px] z-[1000] bg-white `}
          >
            <div
              className={`relative z-[1000] w-[100%] bg-white ${
                isScrolled ? `px-5` : ` py-3`
              }
    ${getClassName()}`}
            >
              <CustomSearchBox
                width="w-[90%] mmsm:w-[65%] sm:w-[55%] md:w-[60%] mmd:w-[50%] lg:w-[40%] xl:w-[35%] 3xl:w-[30%]"
                height="h-[48px] md:h-[50px]"
                iconStyles={{
                  search: 'top-[4.5px] md:top-[7.5px] xl:top-[8px]',
                  clear: 'xxsm:top-[7px] md:top-[8px]'
                }}
                searchTerm={searchQuery}
                setSearchTerm={setSearchQuery}
                defaultCities={
                  searchQuery ? dropdownCities : DEFAULT_DROPDOWN_CITIES
                }
                defaultTours={dropdownTours}
                pagetype={ELandingPage.TOURS_PAGE}
                setSelectedCity={setSelectedTourCity}
              />
            </div>

            <AttractionCategories
              categories={tourCategories}
              pagetype={ELandingPage.TOURS_PAGE}
              handleCategoryClick={handleCategoryClick}
              selectedCategory={selectedCategory}
            />
          </div>
          <div className="mx-5 xsm:mx-6 md:mx-0">
            <LandingPageTours
              hits={JSON.parse(replaceText(JSON.stringify(tours)))}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isLoading={isLoading}
              totalPages={totalPages}
              handleLoadMore={handleLoadMore}
            />
          </div>
          {/* <Configure hitsPerPage={HITS_PER_PAGE} /> */}
        </InstantSearch>

        {!isLoading && (
          <div
            className={`bottom-[90px] relative h-[100%]  sm:h-[450px] md:h-[500px] lg:h-[350px] xl:h-[300px] ${
              currentPage === totalPages ? `mt-[7rem]` : `mb-5`
            }`}
          >
            <RelatedBreadcrumbLinks
              breadcrumbs={popular_cities_breadcrumbs}
              pagetype={ELandingPage.TOURS_PAGE}
            />
          </div>
        )}
        {showNewsletter && <Newsletter />}
      </main>
    </>
  );
};

export default Tours;

export const getStaticProps: GetStaticProps = async () => {
  try {
    // Execute queries in parallel
    const [citiesResponse, attractionsResponse] = await Promise.all([
      client.query({ query: FETCH_ALL_CITIES }),
      client.query({
        query: ATTRACTIONS_BY_POPULAR_CITIES,
        variables: { cityIds: ['17', '2', '13', '18'] }
      })
    ]);

    const cities =
      citiesResponse.data?.citiesPage?.cities?.map((item: any) => ({
        id: item.city.id,
        name: item.city.name,
        url: item.city.slug
      })) || [];

    const attractions =
      attractionsResponse.data?.cities?.flatMap((city: any) =>
        city.attractions.map((attraction: BreadcrumbLink) => ({
          name: attraction.name,
          url: attraction.slug
        }))
      ) || [];

    // Construct breadcrumbs
    const relatedBreadcrumbs = [
      {
        parent: 'Popular Cities',
        links: cities
      },
      {
        parent: 'Popular Attractions',
        links: attractions
      }
    ];

    return {
      props: {
        popular_cities_breadcrumbs: relatedBreadcrumbs
      }
    };
  } catch (error) {
    console.error('Error fetching data:', error);

    // Fallback data if an error occurs
    return {
      props: {
        popular_cities_breadcrumbs: []
      }
    };
  }
};
