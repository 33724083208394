function replaceText(inputString) {
  // Replace "https://www.seesight-tours.com" with "https://www.tripshepherd.com"
  const replacedString1 = inputString.replace(
    /https:\/\/www\.seesight-tours\.com/g,
    'https://www.tripshepherd.com'
  );

  // Replace "See Sight Tours", "seesight", and "see sight" with  "Trip Shepperd"
  const replacedString2 = replacedString1?.replace(
    /See Sight Tours|See Sight Tour|Seesight Tours|Trip Shepperd/gi,
    'Tripshepherd'
  );

  return replacedString2;
}
export function replaceTripShepherd(inputString) {
  // Replace "https://www.seesight-tours.com" with "http://trip-shepperd.com"
  // const replacedString1 = inputString.replace(
  //   /https:\/\/www\.seesight-tours\.com/g,
  //   'http://trip-shepperd.com'
  // );

  // Replace "See Sight Tours", "seesight", and "see sight" with  "Trip Shepperd"
  const replacedString2 = inputString?.replace(
    /Tripshepherd/gi,
    'See Sight Tours'
  );

  return replacedString2;
}

export function replaceCanonical(inputString) {
  // Replace "https://www.seesight-tours.com" with "http://trip-shepperd.com"
  if (!inputString) {
    return;
  }
  const replacedString1 = inputString.replace(
    /https:\/\/www\.seesight-tours\.com/g,
    'https://www.trip-shepperd.com'
  );

  return replacedString1;
}

export default replaceText;
